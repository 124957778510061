.container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #282c34; /* Dark theme background */
  background: linear-gradient(to right, #282c34 50%, #ffffff 50%); /* Gradient blend */
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem; /* Spacing between image and form */
  padding: 2rem;
  border-radius: 2rem;
  box-shadow: 0 20px 50px rgba(0,0,0,0.5); /* Unified shadow to give depth */
  background: linear-gradient(to right, transparent 50%, white 50%);
  backface-visibility: hidden; /* Improves rendering performance */
}

.yetiImage {
  width: auto;
  height: 300px; /* Adjust based on your preference */
  margin-right: -2rem; /* Overlap between image and form */
}

.formContainer {
  background: white;
  padding: 2rem;
  border-radius: 8px;
  /* Removed box-shadow from here */
}

.title {
  color: #333;
  margin-bottom: 0.5rem;
}

.subtitle {
  color: #666;
  margin-bottom: 2rem;
}

.form {
  display: flex;
  flex-direction: column;
}

.inputGroup {
  display: flex;
  flex-direction: column;
}

.inputField {
  padding: 0.75rem;
  margin-bottom: 1rem;
  border: 2px solid #ccc;
  border-radius: 4px;
  transition: border-color 0.3s;
}

.inputField:focus {
  border-color: #007bff; /* Highlight focus */
  outline: none;
}

.submitButton {
  padding: 0.75rem;
  background-image: linear-gradient(45deg, #007bff, #00dbde); /* Gradient background */
  color: white;
  border: none;
  border-radius: 30px; /* More pronounced rounded corners */
  cursor: pointer;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-weight: bold; /* Make the text bold */
  font-size: 1rem;
  letter-spacing: 1px; /* Increase letter spacing for better readability */
  outline: none; /* Remove outline */
}

.submitButton:hover, .submitButton:focus {
  /* Scale the button to 105% of its original size */
  transform: translateY(-3px) scale(1.05);
  /* Enhanced shadow for a 'lifted' effect */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}


.submitButton:active {
  transform: translateY(1px); /* Slight move down on click */
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1); /* Decrease shadow to mimic 'pressing down' effect */
}

.responseMessage{
  color: black;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .container {
    background: linear-gradient(to bottom, #282c34 50%, #ffffff 50%); /* Vertical gradient */
  }

  .content {
    flex-direction: column-reverse; /* Stack form on top of image */
    align-items: center; /* Center-align the flex items */
    background: none; /* Remove the split background */
  }

  .yetiImage {
    height: 200px; /* Smaller image for mobile */
    margin-right: 0; /* Reset the margin overlap */
    margin-bottom: 0rem; /* Overlap image with form */
    z-index: 1; /* Ensure image is above the form background */
  }

  .formContainer {
    padding: 2rem 1rem; /* Adjust padding */
    border-radius: 1rem; /* Smaller border-radius */
  }
}